<template>
  <div class="login flex flex-col items-center justify-center">
    <h2 class="font-bold text-3xl mb-8">Iniciar Sesión</h2>
    <form @submit.prevent="login" class="flex flex-col">
      <input
        class="py-2 px-4 rounded-lg my-4 bg-white"
        type="email"
        placeholder="EMAIL"
        v-model="email"
        required
      />
      <input
        class="py-2 px-4 rounded-lg my-4 bg-white"
        type="password"
        placeholder="CONTRASEÑA"
        v-model="password"
        required
      />
      <button class="bg-yellow text-white rounded-lg py-2">Acceder</button>
    </form>
  </div>
</template>

<script>
  
  import { auth } from '../firebase';
  export default {
    data() {
      return {
        email: '',
        password: ''
      }
    },
    methods: {
      async login() {
        try {
          await auth.signInWithEmailAndPassword(this.email, this.password);
          const token = await auth.currentUser.getIdToken()
          //console.log(token);
          this.$router.push('/dashboard');
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .login {
    min-height: 100vh;
    max-width: 100% !important;
    background-image: url("../assets/images/background_dt.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    font-family: "Nunito";
  }
</style>